import React, { useContext, useEffect, useState } from 'react'
import { StyledH2V2 } from '../../styles/styledComponents'
import Item from '../../components/item'
import add from '../../assets/icons/add.svg'
import MeetingsDataService from '../../services/meetings'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useForm } from 'antd/es/form/Form'
import { Button, Col, Form, Input, Modal, Row, Select, Spin } from 'antd'
import IntegrationsDataService from '../../services/integrations'
import UnlockFeature from '../../components/unlockFeature'
import JamyContext from '../../context/jamyContext'
import SearchBarAutoComplete from '../../components/searchBarAutocomplete'

type PropsTaskContainer = {
  tasks: Meetings.Tasks[]
  url: string
  getMeetingDetail: (meeting_id: string) => void
  recipients: Meetings.Recipients[]
}

const TasksContainer: React.FC<PropsTaskContainer> = ({
  tasks,
  getMeetingDetail,
  recipients,
  url
}) => {
  const [addTaskForm] = useForm()
  const [integrationName, setIntegrationName] = useState('')
  const [idTask, setIdTask] = useState('')
  const [idList, setidList] = useState('')
  const [idBoard, setIdBoard] = useState('')
  const [boards, setBoards] = useState<any>([])
  const [boardsList, setBoardsList] = useState<any>([])
  const [boardsData, setBoardsData] = useState<any>([])

  const [addTask, setAddTask] = useState(false)
  const [loadingAdd, setLoadingAdd] = useState(false)
  const meetingService = new MeetingsDataService()
  const { meeting_id } = useParams()
  const integrationsDataService = new IntegrationsDataService()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchForm] = Form.useForm()
  const [searchBy, setSearchBy] = useState<string[]>([])
  const [filteredProspects, setFilteredProspects] = useState<any>([])
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any>([])
  const { user, permissions } = useContext(JamyContext)
  const [upnifyProspects, setUpnifyProspects] =useState<any>([])

  const isUpnifyAvailable = permissions?.includes('integrations') && user?.integrations.upnify_user

  useEffect(() => {
    if (isUpnifyAvailable && !upnifyProspects.length) {
      integrationsDataService
        .getUpnifyProspects()
        .then((response) => {
          const prospects = response.data.map((element) => ({
            value: element.name,
            label: element.name,
            id: element.id
          }))
          setUpnifyProspects(prospects)
          setAutoCompleteOptions(prospects)
          setBoardsData(response.data)
        })
        .catch((error) => console.error('Error fetching Upnify prospects:', error))
    } else if (integrationName !== '' && integrationName.toLowerCase() !== 'upnify') {
      integrationsDataService
        .getIntegrationsBoards(integrationName.toLowerCase())
        .then((response) => {
          setBoardsData(response.data)
          const boardOptions = response.data.map((element) => ({
            value: element.id,
            label: element.name
          }))
          setBoards(boardOptions)
        })
        .catch((e) => console.error(e))
    }
  }, [integrationName, isUpnifyAvailable, upnifyProspects])

  useEffect(() => {
    if (idBoard !== '') {
      filterDataByIntegration(integrationName)
    }
  }, [idBoard, boardsData, integrationName])

  const filterDataByIntegration = (app: string) => {
    const listOptions: { value: string; label: string }[] = []
    
    switch (app.toLowerCase()) {
      case 'trello':
        const selectedTrelloBoard = boardsData.find((board: any) => board.id === idBoard);
        if (selectedTrelloBoard && selectedTrelloBoard.lists) {
          selectedTrelloBoard.lists.forEach((element: any) => {
            listOptions.push({ value: element.id, label: element.name })
          })
        }
        setBoardsList(listOptions)
        break
      case 'monday':
        const selectedMondayBoard = boardsData.find((board: any) => board.id === idBoard);
        if (selectedMondayBoard && selectedMondayBoard.groups) {
          selectedMondayBoard.groups.forEach((element: any) => {
            listOptions.push({ value: element.id, label: element.title })
          })
        }
        setBoardsList(listOptions)
        break
      case 'upnify':
        // Upnify doesn't need filtering as it's a flat list
        setBoardsList(boardsData)
        break
      default:
        break
    }
  }

  const onClicEditTask = (data: any, index: number) => {
    const tasksTemp = tasks
    const dataPost = {
      section: 'tasks',
      item: {
        ...tasksTemp[index],
        task: data.task
      }
    }

    updateMeetingBySection(dataPost, 'Tasks')
  }

  const onDeleteResponsible = (
    responsible: Meetings.Responsible,
    position: number
  ) => {
    const dataRequest = {
      section: 'tasks',
      item: {
        ...tasks[position - 1],
        responsibles: tasks[position - 1].responsibles.filter(
          (x) => x.email !== responsible.email
        )
      }
    }

    updateMeetingBySection(dataRequest, 'Tasks')
  }

  const updateMeetingBySection = (data: any, arg: string) => {
    if (meeting_id) {
      meetingService
        .updateMeetingBySection(data, meeting_id)
        .then(() => {
          getMeetingDetail(meeting_id)
          toast.success(`${arg} have been updated`, { theme: 'colored' })
        })
        .catch((error) => {
          toast.error(`${arg} haven't been updated correctly`, {
            theme: 'colored'
          })
          console.log(error)
        })
    }
  }

  const deleteMeetingItemBySection = (data: any, arg: string) => {
    if (meeting_id) {
      meetingService
        .deleteMeetingBySection(data, meeting_id)
        .then(() => {
          getMeetingDetail(meeting_id)
          toast.success(`${arg} have been updated`, { theme: 'colored' })
        })
        .catch((error) => {
          toast.error(`${arg} haven't been updated correctly`, {
            theme: 'colored'
          })
          console.log(error)
        })
    }
  }

  const onClicDeleteTask = (index: number) => {
    const data = {
      section: 'tasks',
      item_id: tasks[index - 1].id
    }
    deleteMeetingItemBySection(data, 'Tasks')
  }

  const getTaskStatus = (status?: string) => {
    switch (status) {
      case 'done':
        return true
      default:
        return false
    }
  }

  const onFinish = (data: { task: string }) => {
    setLoadingAdd(true)
    const dataRequest = {
      section: 'tasks',
      item: {
        task: data.task,
        responsibles: []
      }
    }
    meetingService
      .createItemMeeting(dataRequest, meeting_id ? meeting_id : '')
      .then(() => {
        addTaskForm.resetFields()
        getMeetingDetail(meeting_id ? meeting_id : '')
        setAddTask(false)
        setLoadingAdd(false)
        toast.success('Task created correctly.', { theme: 'colored' })
      })
      .catch(() => {
        setLoadingAdd(false)
        toast.error('Task has not created correctly.', { theme: 'colored' })
      })
  }

  const onChange = (e: any, index: number) => {
    const dataRequest = {
      section: 'tasks',
      item: {
        ...tasks[index],
        status: e.target.checked ? 'done' : 'to_do'
      }
    }
    updateMeetingBySection(dataRequest, 'Tasks')
  }

  const onAssignTask = (item: any, position: number) => {
    const dataRequest = {
      section: 'tasks',
      item: {
        ...tasks[position - 1],
        responsibles: getResponsiblesInfo(item, position)
      }
    }
    updateMeetingBySection(dataRequest, 'Tasks')
  }

  const getResponsiblesInfo = (arr: string[], position: number) => {
    let responsibles: Meetings.Responsible[] = []

    arr.forEach((element) => {
      responsibles.push({
        email: element,
        last_name: recipients.filter((x) => x.email === element)
          ? recipients.filter((x) => x.email === element)[0].last_name
          : '',
        first_name: recipients.filter((x) => x.email === element)
          ? recipients.filter((x) => x.email === element)[0].first_name
          : ''
      })
    })

    return responsibles
  }

  const onClicDescriptionEdit = (data: {
    description: string
    index: number
  }) => {
    const tasksTemp = tasks
    const dataRequest = {
      section: 'tasks',
      item: {
        ...tasksTemp[data.index - 1],
        description: data.description
      }
    }

    updateMeetingBySection(dataRequest, 'Tasks')
  }

  const handleChange = (value: string) => {
    setIdBoard(value)
  }

  const handleChangeList = (value: string) => {
    setidList(value)
  }

  const showModals = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    createCard(integrationName)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const createCard = (app: string) => {
    switch (app) {
      case 'Trello':
        integrationsDataService
          .createTrelloCard({
            trello_list_id: idList,
            meeting_id: meeting_id ? meeting_id : '',
            task_id: idTask
          })
          .then(() => {
            getMeetingDetail(meeting_id ? meeting_id : '')
            setidList('')
            setIdBoard('')
            toast.success('Card created correctly', { theme: 'colored' })
          })
          .catch((e) => console.error(e))
        break
      case 'Monday':
        integrationsDataService
          .createMondayCard({
            monday_board_id: idBoard,
            monday_group_id: idList,
            meeting_id: meeting_id ? meeting_id : '',
            task_id: idTask
          })
          .then(() => {
            getMeetingDetail(meeting_id ? meeting_id : '')
            setidList('')
            setIdBoard('')
            toast.success('Item created correctly', { theme: 'colored' })
          })
          .catch((e) => console.error(e))
        break
      case 'Upnify':
        integrationsDataService
          .createUpnifyTask({
            upnify_prospect_id: idBoard,
            meeting_id: meeting_id ? meeting_id : '',
            task_id: idTask
          })
          .then(() => {
            getMeetingDetail(meeting_id ? meeting_id : '')
            setIdTask('')
            toast.success('Task created correctly', { theme: 'colored' })
          })
          .catch((e) => console.error(e))
        break
      default:
        break
    }
  }

  const handleSearch = (selectedValue: string) => {
    const selectedProspect = upnifyProspects.find((prospect: any) => prospect.value === selectedValue)
    if (selectedProspect) {
      setIdBoard(selectedProspect.id)
    }
  }

  const handleAutoCompleteSearch = (value: string) => {
    const searchValue = value?.toLowerCase() || '';
    const filteredOptions = upnifyProspects.filter((prospect: any) => {
      if (!prospect || typeof prospect.label !== 'string') return false;
      return prospect.label.toLowerCase().includes(searchValue);
    });
    setAutoCompleteOptions(filteredOptions);
  }

  return (
    <div>
      <Modal
        title={integrationName}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {integrationName === 'Trello' || integrationName === 'Monday' ? (
          <Row gutter={16}>
            <Col>
              <Select
                style={{ width: 200 }}
                placeholder={'Select board'}
                onChange={handleChange}
                options={boards}
              />
            </Col>
            <Col>
              <Select
                style={{ width: 200 }}
                placeholder={'Select List'}
                onChange={handleChangeList}
                options={boardsList}
              />
            </Col>
          </Row>
        ) : integrationName === 'Upnify' ? (
          <>
            <SearchBarAutoComplete
              placeholder="Search prospects and clients"
              onSelect={handleSearch}
              handleSearch={handleAutoCompleteSearch}
              options={autoCompleteOptions}
            />
          </>
        ) : null}
      </Modal>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <StyledH2V2>Tasks</StyledH2V2>
        <img
          src={add}
          alt="add"
          style={{ cursor: 'pointer', margin: '0 10px' }}
          onClick={() => setAddTask(!addTask)}
        />
      </div>
      <div hidden={addTask ? false : true}>
        <Form form={addTaskForm} onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={22}>
              <Form.Item name={'task'}>
                <Input placeholder="Add new task" disabled={loadingAdd}></Input>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item>
                {loadingAdd ? (
                  <Spin />
                ) : (
                  <Button type="primary" htmlType="submit">
                    +
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {tasks.map((item, index) => {
        return (
          <Item
            onClicFinishDescription={onClicDescriptionEdit}
            item={item}
            nameInput="task"
            key={index}
            title={item.task}
            position={index + 1}
            onFinish={(data, index) => onClicEditTask(data, index)}
            onDeleteResponsible={(
              responsible: Meetings.Responsible,
              position: number
            ) => onDeleteResponsible(responsible, position)}
            onMove={function (): void {
              throw new Error('Function not implemented.')
            }}
            onChecked={(e: any) => onChange(e, index)}
            onDelete={onClicDeleteTask}
            onAssign={onAssignTask}
            responsibles={item.responsibles}
            recipients={recipients}
            numbers={false}
            isDone={getTaskStatus(item.status)}
            onHandleIntegration={(app) => {
              showModals()
              setIntegrationName(app)
              setIdTask(item.id)
            }}
            url={url}
          />
        )
      })}
    </div>
  )
}

export default TasksContainer
