import { http } from '../../config/http-common'

class MeetingsDataService {
  getAllMeetings() {
    return http.get<Meetings.Response>('/meetings/')
  }

  getAllMeetingsByMonth(month: number) {
    return http.get<Meetings.Response>(`/calendar/meetings/${month}/`)
  }

  getAllMeetingsByDate(date: string) {
    const year = date.split('-')[2]
    const month = date.split('-')[1]
    const day = date.split('-')[0]
    return http.get<Meetings.Response>(
      `/calendar/meetings/${year}/${month}/${day}/`
    )
  }

  getMeetingById(meeting_id: string) {
    return http.get<Meetings.Results>(`/meetings/${meeting_id}/`)
  }

  getMeetingsSearch(words: string) {
    return http.get<Meetings.Response>(`/meetings_search/?query=${words}`)
  }

  createItemMeeting(data: any, meeting_id: string) {
    return http.post<any>(`items/meeting/${meeting_id}/`, data)
  }

  updateMeeting(data: any, meeting_id: string) {
    return http.patch<any>(`meetings/${meeting_id}/`, data)
  }

  updateMeetingBySection(data: any, meeting_id: string) {
    return http.patch<any>(`items/meeting/${meeting_id}/`, data)
  }

  deleteMeetingBySection(data: any, meeting_id: string) {
    return http.delete<any>(`items/meeting/${meeting_id}/`, { data: data })
  }

  processMeeting(data: any) {
    return http.post<any>(`correct_meeting_spelling/`, data)
  }

  sendNotes(meeting_id: string, slack?: boolean, email?: boolean) {
    return http.post<any>(`/notifications/meetings/`, {
      meeting_id: meeting_id,
      slack: slack,
      email: email
    })
  }

  getMeetingBanner(email: string | null) {
    return http.get<Meetings.BannerResponse>(
      `/meetings/user/banner/?email=${email}`
    )
  }

  updateMeetingComponent(component_meeting_id: string, data: Meetings.Result) {
    return http.patch(`/meeting_components/${component_meeting_id}/`, data)
  }
}

export default MeetingsDataService
