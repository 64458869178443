import React, { useContext } from 'react'
import CardIntegration from '../../components/cardIntegration'
import zoom from '../../assets/integrations/zoom.svg'
import JamyContext from '../../context/jamyContext'

const ZoomIntegration: React.FC = () => {
  const { permissions } = useContext(JamyContext)
  return (
    <CardIntegration
      category="meetings"
      description="Jamy will join and record your Zoom meetings"
      name="Zoom"
      isConnected={true}
      icon={zoom}
      isPermitted={permissions.includes('zoom')}
    />
  )
}

export default ZoomIntegration
