import React, { useContext } from 'react'
import trello from '../../assets/integrations/trello.svg'
import JamyContext from '../../context/jamyContext'
import CardIntegration from '../../components/cardIntegration'

const TrelloIntegration: React.FC = () => {
  const { user } = useContext(JamyContext)
  const { permissions } = useContext(JamyContext)
  return (
    <CardIntegration
      category="productivity"
      description="Convert Jamy tasks into Trello cards"
      name="Trello"
      isConnected={user?.integrations.trello_user !== null ? true : false}
      onClick={() =>
        window.location.replace(
          'https://trello.com/1/authorize?expiration=never&name=Jamy.ai&scope=read,write,account&response_type=token&key=3d5156bc10ad57839e7172b1f10d1c0b&callback_method=fragment&return_url=http://app.jamy.ai/welcome'
        )
      }
      icon={trello}
      isPermitted={permissions.includes('integrations')}
    />
  )
}

export default TrelloIntegration
