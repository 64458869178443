import React, { useEffect, useState } from 'react'
import { StyledH2V2 } from '../../../styles/styledComponents'
import edit from '../../../assets/icons/edit.svg'
import { Button, Form, Input } from 'antd'
import { useForm } from 'antd/es/form/Form'
import MeetingsDataService from '../../../services/meetings'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
type PropsGeneration = {
  generationItem?: Meetings.Reports
  getMeetingDetail: (meetingId: string) => void
}

const Generation: React.FC<PropsGeneration> = ({
  generationItem,
  getMeetingDetail
}) => {
  const [seeMoreText, setSeeMoreText] = useState('See more')
  const [valueEdit, setValueEdit] = useState(false)
  const [valueArray, setValueArray] = useState<string[]>([])
  const [form] = useForm()
  const { meeting_id } = useParams()
  useEffect(() => {
    if (generationItem) {
      setValueArray(
        generationItem.result.result[0].value.split('\n\n').slice(0, 2)
      )
      form.setFieldValue('value', generationItem.result.result[0].value)
    }
  }, [generationItem])

  const seeMore = (more: boolean) => {
    if (more) {
      setSeeMoreText('Hide')
      setValueArray(
        generationItem
          ? generationItem.result.result[0].value.split('\n\n')
          : []
      )
    } else {
      setSeeMoreText('See more')
      setValueArray(
        generationItem
          ? generationItem.result.result[0].value.split('\n\n').slice(0, 2)
          : []
      )
    }
  }

  const updateMeeting = (data: any, arg: string) => {
    const meetingService = new MeetingsDataService()
    if (generationItem) {
      meetingService
        .updateMeetingComponent(generationItem.id, data)
        .then(() => {
          setValueEdit(!valueEdit)
          getMeetingDetail(meeting_id ? meeting_id : '')
          toast.success(`${arg} have been updated`, { theme: 'colored' })
        })
        .catch((error) => {
          toast.error(`${arg} haven't been updated correctly`, {
            theme: 'colored'
          })
          console.log(error)
        })
    }
  }

  const onClicFinishValue = (data: { value: string }) => {
    if (generationItem) {
      const result = {
        result: {
          result: [{ value: data.value }]
        }
      }
      updateMeeting(result, generationItem.component.name)
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <StyledH2V2>
          {generationItem && generationItem.component.name}
        </StyledH2V2>
        <img
          src={edit}
          alt="edit"
          style={{ cursor: 'pointer', margin: '0 10px' }}
          onClick={() => {
            setValueEdit(!valueEdit)
          }}
        />
      </div>
      <div hidden={valueEdit}>
        {valueArray.map((p, index) => {
          return (
            <p key={index} style={{ lineHeight: '24px' }}>
              {p}
            </p>
          )
        })}
        <p
          style={{ color: '#E0AA25', cursor: 'pointer' }}
          onClick={() => seeMore(seeMoreText === 'See more' ? true : false)}
        >
          <strong>{seeMoreText}</strong>
        </p>
      </div>
      <Form form={form} onFinish={onClicFinishValue} hidden={!valueEdit}>
        <Form.Item name={'value'}>
          <Input.TextArea
            size="middle"
            style={{ height: '300px' }}
          ></Input.TextArea>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Generation
