import React, { useContext } from 'react'
import CardIntegration from '../../components/cardIntegration'
import JamyContext from '../../context/jamyContext'
import monday from '../../assets/integrations/monday.svg'

const MondayIntegration: React.FC = () => {
  const { user } = useContext(JamyContext)
  const { permissions } = useContext(JamyContext)
  return (
    <CardIntegration
      category="productivity"
      description="Convert Jamy tasks into Monday items"
      name="Monday"
      isConnected={user?.integrations.monday_user !== null ? true : false}
      onClick={() =>
        window.location.replace(
          'https://auth.monday.com/oauth2/authorize?client_id=509ef44f4e86aea3122aa27f68b838de'
        )
      }
      icon={monday}
      isPermitted={permissions.includes('integrations')}
    />
  )
}

export default MondayIntegration
