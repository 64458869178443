import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import 'video-react/dist/video-react.css'
import './transcript.css' // Assume we have some CSS for highlighting
import { RecordingButton } from '../meetingsDetailsContainer/styledComponents'
import { ArrowDownOutlined } from '@ant-design/icons'

type TranscriptContainerProps = {
  transcriptJSON: any
  urlVideo: string
  urlFile: string | undefined
  open: boolean
}

const TranscriptContainer: React.FC<TranscriptContainerProps> = ({
  transcriptJSON,
  urlVideo,
  urlFile,
  open
}) => {
  const [currentTime, setCurrentTime] = useState(0)
  const transcriptRef = useRef<HTMLDivElement>(null)
  const playerRef = useRef<ReactPlayer>(null)
  const isScrollingRef = useRef(false)

  const handleProgress = (state: any) => {
    setCurrentTime(state.playedSeconds)
  }

  const getHighlightedWords = (words: any) => {
    return words.map((word: any, index: any) => (
      <span
        key={index}
        id={`word-${word.start}`}
        className={
          currentTime >= word.start && currentTime <= word.end
            ? 'highlight'
            : ''
        }
      >
        {word.word}{' '}
      </span>
    ))
  }

  useEffect(() => {
    const activeWord = transcriptJSON
      .flatMap((segment: any) => segment.words)
      .find((word: any) => currentTime >= word.start && currentTime <= word.end)
    const wordElement = activeWord
      ? document.getElementById(`word-${activeWord.start}`)
      : null

    if (wordElement && transcriptRef.current && !isScrollingRef.current) {
      wordElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [currentTime, transcriptJSON])

  // const handleScroll = () => {
  //   if (transcriptRef.current && playerRef.current) {
  //     const { scrollTop, clientHeight, scrollHeight } = transcriptRef.current
  //     const scrollFraction = scrollTop / (scrollHeight - clientHeight)
  //     const newTime = scrollFraction * playerRef.current.getDuration()
  //     playerRef.current.seekTo(newTime, 'seconds')
  //   }
  // }

  const handleScrollStart = () => {
    isScrollingRef.current = true
  }

  const handleScrollEnd = () => {
    isScrollingRef.current = false
  }

  const convertirSegundosAMinutosYSegundos = (segundosStr: string) => {
    // Convertir la cadena a número
    let segundos = parseFloat(segundosStr)

    // Convertir a minutos y segundos
    let minutos = Math.floor(segundos / 60) // Convertir a minutos
    let segundosRestantes = (segundos % 60).toFixed(0) // Obtener los segundos restantes y redondear

    // Asegurarse de que los segundos siempre tengan dos dígitos
    segundosRestantes =
      parseInt(segundosRestantes, 10) < 10
        ? '0' + segundosRestantes
        : segundosRestantes

    return `${minutos}:${segundosRestantes}`
  }

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
        width: '100%'
      }}
    >
      <div
        style={{
          width: '75%',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          borderRight: '1px solid #e8e8e8',
        }}
      >
        <ReactPlayer
          ref={playerRef}
          url={urlVideo}
          controls
          playing={open}
          onProgress={handleProgress}
          width="100%"
          height="auto"
          style={{ marginBottom: '20px' }}
        />
        <RecordingButton
          style={{ margin: '0 auto', width: '200px' }}
          onClick={() => window.open(urlFile)}
        >
          <ArrowDownOutlined />
          <p style={{ margin: '0 5px' }}>Download Transcript</p>
        </RecordingButton>
      </div>
  
      <div
        className="transcript"
        ref={transcriptRef}
        onMouseDown={handleScrollStart}
        onMouseUp={handleScrollEnd}
        onTouchStart={handleScrollStart}
        onTouchEnd={handleScrollEnd}
        style={{
          width: '25%',
          height: '80%',
          overflowY: 'auto',
          padding: '20px'
        }}
      >
        {transcriptJSON.map((segment: any, index: any) => (
          <div key={index} className="segment">
            <div
              className="timestamp"
              style={{ fontWeight: 'bold', marginBottom: '5px' }}
            >
              {convertirSegundosAMinutosYSegundos(segment.start)}
            </div>
            <div
              style={{
                fontWeight: 'bold',
                color: 'rgb(83, 51, 193)',
                marginBottom: '10px'
              }}
            >
              {segment.speaker}
            </div>
            <div className="words" style={{ wordWrap: 'break-word' }}>
              {getHighlightedWords(segment.words)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )  
}

export default TranscriptContainer
