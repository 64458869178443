import React from 'react'
import {
  StyledItemFormsAnswer,
  StyledItemFormsContainer,
  StyledItemFormsContent,
  StyledItemFormsNumber,
  StyledItemFormsOptions,
  StyledItemFormsQuestion
} from './styledComponents'

export type PropsItemForms = {
  index?: number
  result: {
    name: string
    value: string
  }
}

const ItemForms: React.FC<PropsItemForms> = ({ result, index }) => {
  return (
    <StyledItemFormsContainer>
      <StyledItemFormsContent>
        <StyledItemFormsNumber>{index}</StyledItemFormsNumber>
        <StyledItemFormsQuestion>{result.name}</StyledItemFormsQuestion>
      </StyledItemFormsContent>
      <StyledItemFormsAnswer>{result.value}</StyledItemFormsAnswer>
      <StyledItemFormsOptions></StyledItemFormsOptions>
    </StyledItemFormsContainer>
  )
}

export default ItemForms
