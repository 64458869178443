import { useEffect, useReducer, useState } from 'react'
import { toast } from 'react-toastify'
import AuthDataService from '../services/auth'
import ILoginUserData from '../types/auth/login'
import JamyContext, { initialState } from './jamyContext'
import JamyReducer from './jamyReducer'
import { createBrowserHistory } from 'history'
import UserDataService from '../services/users'
import ILogoutUserData from '../types/auth/logout'
import Loading from '../components/loading'
import OrgsDataService from '../services/org'
import MeetUsersDataService from '../services/meetUsers'
import SlackUsersDataService from '../services/slackUsers'
import CalendarsDataService from '../services/calendars'
import { PermissionsList } from '../hooks/usePermissions/data'
export const browserHistory = createBrowserHistory()

const JamyState = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(JamyReducer, initialState)
  const [productId, setProductId] = useState('')
  const [, setGoogleConection] = useState<any>()
  const authService = new AuthDataService()
  const userService = new UserDataService()
  const orgsService = new OrgsDataService()
  const meetUserService = new MeetUsersDataService()
  const slackUserService = new SlackUsersDataService()
  const calendarService = new CalendarsDataService()

  useEffect(() => {
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (productId !== '') {
      getPermissions()
    }
  }, [productId])

  const getUser = () => {
    if (localStorage.getItem('access-token')) {
      const accessToken = localStorage.getItem('access-token')
      const id = localStorage.getItem('id')
      if (accessToken && id) {
        getRecallToken()
        getUserInformation(id, accessToken)
        getOrgInformation()
        getAllSlackUsers()
        getCalendars()
      }

      dispatch({
        type: 'LOGIN',
        payload: { accessToken, id }
      })
    }
  }

  const login = async (data: ILoginUserData) => {
    var regexPattern = new RegExp('true')
    dispatch({
      type: 'LOADING',
      payload: true
    })
    try {
      const res = await authService.login(data)
      localStorage.setItem('access-token', res.data.key)
      localStorage.setItem('id', res.data.user_id)
      await getUserInformation(res.data.user_id, res.data.key)
      dispatch({
        type: 'LOGIN',
        payload: res.data
      })
      await getOrgInformation()
      await getAllSlackUsers()
      dispatch({
        type: 'LOADING',
        payload: false
      })
      const isValidated = localStorage.getItem('is_validated')
      const googleConnection = localStorage.getItem('google_connection')
      const isOrgActive = localStorage.getItem('is_active')
      const isOTest = regexPattern.test(isOrgActive ? isOrgActive : '')
      const isVTest = regexPattern.test(isValidated ? isValidated : '')
      const isGCTest = regexPattern.test(
        googleConnection ? googleConnection : ''
      )
      if (data.redirect) {
        browserHistory.push(data.redirect)
      } else {
        if (isVTest === true && isGCTest === true && isOTest === true) {
          browserHistory.push('/dashboard')
        } else {
          browserHistory.push('/welcome')
        }
      }
    } catch (e: any) {
      dispatch({
        type: 'LOADING',
        payload: false
      })
      toast.error(e.response.data.error[0], { theme: 'colored' })
    }
  }

  const logout = async (data: ILogoutUserData) => {
    dispatch({
      type: 'LOADING',
      payload: true
    })
    try {
      const res = await authService.logout(data)
      dispatch({
        type: 'LOGOUT',
        payload: res.data
      })
      window.location.replace('/login')
    } catch (e: any) {
      dispatch({
        type: 'LOADING',
        payload: false
      })
      toast.error(e.response.data.non_field_errors[0], { theme: 'colored' })
      console.log(e)
    }
  }

  const getUserInformation = async (user_id: string, accessToken: string) => {
    try {
      const res = await userService.getUser(user_id, accessToken)
      localStorage.setItem(
        'is_validated',
        res.data.is_validated ? 'true' : 'false'
      )
      setGoogleConection(res.data.meet_user)
      dispatch({
        type: 'USER',
        payload: res.data
      })
    } catch (e: any) {
      toast.error(e.response.data.non_field_errors[0], { theme: 'colored' })
      console.log(e)
    }
  }

  const getOrgInformation = async () => {
    try {
      const res = await orgsService.getOrganization()
      localStorage.setItem(
        'is_active',
        res.data.results[0].active ? 'true' : 'false'
      )
      setProductId(
        res.data.results[0].pricing_plan
          ? res.data.results[0].pricing_plan.product_id
          : 'prod_Qh27wDtOVJbXqB'
      )
      dispatch({
        type: 'ORG',
        payload: res.data.results[0]
      })
    } catch (e: any) {
      console.log('getOrgInformation Error')
      toast.error(e.response.data.non_field_errors[0], { theme: 'colored' })
      console.log(e)
    }
  }

  const getAllMeetUsers = async () => {
    try {
      const res = await meetUserService.getAllMeetUsers()
      const user = localStorage.getItem('id')
      if (res.data.length > 0) {
        const gConection = res.data.filter((x: any) => x.id === user)[0]

        setGoogleConection(res.data.filter((x: any) => x.user === user)[0])
        localStorage.setItem('google_connection', 'true')

        dispatch({
          type: 'GOOGLE_CONNECTION',
          payload: gConection ? gConection.google_connection : false
        })
        dispatch({
          type: 'MEET_USER',
          payload: gConection ? gConection.id : undefined
        })
      }
    } catch (e: any) {
      console.log('getAllMeetUsers Error')
      toast.error(e.response, { theme: 'colored' })
      console.log(e)
    }
  }

  const getAllSlackUsers = async () => {
    try {
      const res = await slackUserService.getAllSlackUsers()
      dispatch({
        type: 'SLACK_CONNECTION',
        payload:
          res.data.results.length > 0
            ? res.data.results[0].slack_connection
            : false
      })
    } catch (e: any) {
      console.log('getAllSlackUsers Error')
      toast.error(e.response.data.non_field_errors[0], { theme: 'colored' })
      console.log(e)
    }
  }

  const getRecallToken = async () => {
    try {
      const res = await authService.recallToken()
      dispatch({
        type: 'RECALL',
        payload: res.data.token
      })
    } catch (e: any) {
      console.log('getRecallToken Error')
      toast.error(e.response.data.non_field_errors[0], { theme: 'colored' })
      console.log(e)
    }
  }

  const getCalendars = async () => {
    try {
      const res = await calendarService.getCalendar()
      const calendarsGoogle = res.data.connections.filter(
        (x: any) => x.platform === 'google'
      )

      const calendarsMS = res.data.connections.filter(
        (x: any) => x.platform === 'microsoft'
      )
      dispatch({
        type: 'RECALL_GOOGLE',
        payload: calendarsGoogle[0].connected
      })

      dispatch({
        type: 'RECALL_MS',
        payload: calendarsMS[0].connected
      })
    } catch (e: any) {
      console.log('getRecallToken Error')
      toast.error(e.response.data.non_field_errors[0], { theme: 'colored' })
      console.log(e)
    }
  }

  const getPermissions = async () => {
    try {
      const res: (string | undefined)[] = PermissionsList.filter(
        (x) => x.id === productId
      )[0].access
      dispatch({
        type: 'PERMISSIONS',
        payload: res ? res : []
      })
    } catch (e: any) {
      console.log('getPermissions Error')
      toast.error('getPermissions Error', { theme: 'colored' })
      console.log(e)
    }
  }

  return (
    <JamyContext.Provider
      value={{
        ...initialState,
        loading: state.loading,
        user: state.user,
        org: state.org,
        permissions: state.permissions,
        recall_calendar_auth_token: state.recall_calendar_auth_token,
        google_connection: state.google_connection,
        slack_connection: state.slack_connection,
        login,
        logout,
        getAllSlackUsers,
        getUser,
        getOrgInformation,
        isAuthenticated: state.isAuthenticated,
        google_calendar_connection: state.google_calendar_connection,
        outlook_calendar_connection: state.outlook_calendar_connection
      }}
    >
      {state.loading ? <Loading /> : children}
    </JamyContext.Provider>
  )
}

export default JamyState
