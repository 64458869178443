import React from 'react'
import {
  StyledPricingTableCard,
  StyledPricingTableCardSelected,
  StyledPricingTableFeature,
  StyledPricingTableFeaturesList,
  StyledPricingTableFeaturesListTitle,
  StyledPricingTablePrice,
  StyledPricingTablePriceCompare,
  StyledPricingTablePriceDescription,
  StyledPricingTableTitle,
  StyledPricingTableUpgradeButton
} from './styledComponents'

type PropsPricingTable = {
  item: Princing.Table
  pricingPlanSelected: Princing.Plan | undefined
  recurring: string
  onClickUpgrade: (productName: string, type: string, priceID: string) => void
}

const PricingTable: React.FC<PropsPricingTable> = ({
  item,
  pricingPlanSelected,
  recurring,
  onClickUpgrade
}) => {
  const planSelected =
    item.prices.filter(
      (x) =>
        x.recurring.interval === (recurring === 'Monthly' ? 'month' : 'year')
    ).length > 0
      ? item.prices.filter(
          (x) =>
            x.recurring.interval ===
            (recurring === 'Monthly' ? 'month' : 'year')
        )[0].id
      : undefined


  return (
    <React.Fragment>
      {planSelected && (
        <StyledPricingTableCard
          isSelected={pricingPlanSelected?.price_id === planSelected}
          isMostPopular={item.name === 'Pro'}
        >
          <StyledPricingTableCardSelected
            hidden={
              item.name === 'Pro' && pricingPlanSelected === undefined
                ? false
                : true
            }
          >
            MOST POPULAR
          </StyledPricingTableCardSelected>
          <StyledPricingTableCardSelected
            hidden={pricingPlanSelected?.price_id !== planSelected}
          >
            Current Plan
          </StyledPricingTableCardSelected>
          <StyledPricingTableTitle>{item.name}</StyledPricingTableTitle>
          <p style={{ height: '100px', padding: '10px', fontSize: '13px' }}>
            {item.description ? item.description.split('Visit')[0] : ''}
          </p>

          <div
            style={{
              height: '100px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledPricingTablePriceCompare
                hidden={recurring === 'Monthly' ? true : false}
              >
                $
                {item.prices.filter(
                  (x) =>
                    x.recurring.interval ===
                    (recurring === 'Yearly' ? 'month' : 'year')
                ).length > 0
                  ? item.prices.filter(
                      (x) =>
                        x.recurring.interval ===
                        (recurring === 'Yearly' ? 'month' : 'year')
                    )[0].unit_amount / 100
                  : 0}
              </StyledPricingTablePriceCompare>
              <StyledPricingTablePrice>
                $ {item.default_price_converted}
              </StyledPricingTablePrice>
            </div>

            <StyledPricingTablePriceDescription>
              {item.name === 'Pay-per-minute'
                ? 'Billed monthly based on usage'
                : recurring === 'Monthly'
                ? 'Per seat, per month'
                : 'Per seat, per month, billed annually'}
            </StyledPricingTablePriceDescription>
          </div>
          <StyledPricingTableUpgradeButton
            hidden={pricingPlanSelected?.price_id === planSelected}
            onClick={() =>
              onClickUpgrade(
                pricingPlanSelected && pricingPlanSelected.price_id !== planSelected
                  ? item.id
                  : planSelected,
                  pricingPlanSelected && pricingPlanSelected.price_id 
                  ? 'upgrade'
                  : 'subscribe',
                planSelected
              )
            }
          >
            {pricingPlanSelected && pricingPlanSelected.price_id 
              ? 'Upgrade'
              : 'Get started'}
          </StyledPricingTableUpgradeButton>
          <StyledPricingTableFeaturesListTitle
            style={{ marginTop: `${true ? '60px' : '0'}` }}
          >
            This includes:
          </StyledPricingTableFeaturesListTitle>
          <StyledPricingTableFeaturesList>
            {item.features.map((item, index) => {
              return (
                <StyledPricingTableFeature key={index}>
                  {item.name}
                </StyledPricingTableFeature>
              )
            })}
          </StyledPricingTableFeaturesList>
        </StyledPricingTableCard>
      )}
    </React.Fragment>
  )
}

export default PricingTable
