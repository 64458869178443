import { http } from '../../config/http-common'

class TemplatesDataService {
  getAllTemplates() {
    return http.get<Templates.ResponseTemplates>(`/templates/`)
  }

  createTemplate(data: any) {
    return http.post<any>(`/meeting_templates/`, data)
  }
}

export default TemplatesDataService
