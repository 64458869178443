import React, { useContext, useEffect, useState } from 'react'
import { StyledH2V2 } from '../../styles/styledComponents'
import Item from '../../components/item'
import add from '../../assets/icons/add.svg'
import MeetingsDataService from '../../services/meetings'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useForm } from 'antd/es/form/Form'
import { Button, Col, Form, Input, Row, Spin } from 'antd'
import UnlockFeature from '../../components/unlockFeature'
import JamyContext from '../../context/jamyContext'

type PropsTopicsContainer = {
  topics: Meetings.Topics[]
  url: string
  getMeetingDetail: (meeting_id: string) => void
}

const TopicsContainer: React.FC<PropsTopicsContainer> = ({
  topics,
  getMeetingDetail,
  url
}) => {
  const { meeting_id } = useParams()
  const meetingService = new MeetingsDataService()
  const [addTopics, setAddTopics] = useState(false)
  const [loadingAdd, setLoadingAdd] = useState(false)
  const [addTopicForm] = useForm()

  const onClicEditTopics = (data: any, index: number) => {
    const dataRequest = {
      section: 'topics',
      item: {
        ...topics[index],
        topic: data.topic
      }
    }

    meetingService
      .updateMeetingBySection(dataRequest, meeting_id ? meeting_id : '')
      .then(() => {
        getMeetingDetail(meeting_id ? meeting_id : '')
        toast.success(`Topics have been updated`, { theme: 'colored' })
      })
      .catch((error) => {
        toast.error(`Topics haven't been updated correctly`, {
          theme: 'colored'
        })
        console.log(error)
      })
  }

  const onFinish = (data: { topic: string }) => {
    setLoadingAdd(true)
    const dataRequest = {
      section: 'topics',
      item: {
        topic: data.topic
      }
    }
    meetingService
      .createItemMeeting(dataRequest, meeting_id ? meeting_id : '')
      .then(() => {
        addTopicForm.resetFields()
        getMeetingDetail(meeting_id ? meeting_id : '')
        setAddTopics(false)
        setLoadingAdd(false)
        toast.success('Topic created correctly.', { theme: 'colored' })
      })
      .catch(() => {
        setLoadingAdd(false)
        addTopicForm.resetFields()
        toast.error('Topic has not created correctly.', { theme: 'colored' })
      })
  }

  const onClicDeleteTopic = (index: number) => {
    const data = {
      section: 'topics',
      item_id: topics[index - 1].id
    }
    deleteMeetingItemBySection(data, 'Topics')
  }

  const deleteMeetingItemBySection = (data: any, arg: string) => {
    if (meeting_id) {
      meetingService
        .deleteMeetingBySection(data, meeting_id)
        .then(() => {
          getMeetingDetail(meeting_id)
          toast.success(`${arg} have been updated`, { theme: 'colored' })
        })
        .catch((error) => {
          toast.error(`${arg} haven't been updated correctly`, {
            theme: 'colored'
          })
          console.log(error)
        })
    }
  }

  const onClicDescriptionEdit = (data: {
    description: string
    index: number
  }) => {
    const topicsTemp = topics
    const dataRequest = {
      section: 'topics',
      item: {
        ...topicsTemp[data.index - 1],
        description: data.description
      }
    }

    meetingService
      .updateMeetingBySection(dataRequest, meeting_id ? meeting_id : '')
      .then(() => {
        getMeetingDetail(meeting_id ? meeting_id : '')
        toast.success(`Topics have been updated`, { theme: 'colored' })
      })
      .catch((error) => {
        toast.error(`Topics haven't been updated correctly`, {
          theme: 'colored'
        })
        console.log(error)
      })
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <StyledH2V2>Topics</StyledH2V2>
        <img
          src={add}
          alt="add"
          style={{ cursor: 'pointer', margin: '0 10px' }}
          onClick={() => setAddTopics(!addTopics)}
        />
      </div>
      <div hidden={addTopics ? false : true}>
        <Form form={addTopicForm} onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={22}>
              <Form.Item name={'topic'}>
                <Input
                  placeholder="Add new topic"
                  disabled={loadingAdd}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item>
                {loadingAdd ? (
                  <Spin />
                ) : (
                  <Button type="primary" htmlType="submit">
                    +
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {topics.map((item, index) => {
        return (
          <Item
            item={item}
            nameInput="topic"
            onClicFinishDescription={onClicDescriptionEdit}
            key={index}
            title={item.topic}
            position={index + 1}
            onFinish={(data, index) => onClicEditTopics(data, index)}
            onMove={function (): void {
              throw new Error('Function not implemented.')
            }}
            onDelete={onClicDeleteTopic}
            responsibles={[]}
            numbers={true}
            url={url}
          />
        )
      })}


    </div>
  )
}

export default TopicsContainer
