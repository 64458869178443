import React, { useContext } from 'react'
import CardIntegration from '../../components/cardIntegration'
import JamyContext from '../../context/jamyContext'
import slack from '../../assets/integrations/slack.svg'

export const SlackIntegration: React.FC = () => {
  const { user } = useContext(JamyContext)
  const { permissions } = useContext(JamyContext)
  
  return (
    <CardIntegration
      category="communication"
      description="Share meeting reports and ask Jamy questions"
      name="Slack"
      isConnected={user?.integrations.slack_user !== null ? true : false}
      onClick={
        user?.integrations.slack_user !== null
          ? undefined
          : () =>
              window.open(
                'https://slack.com/oauth/v2/authorize?client_id=5124423588130.5121502332821&scope=app_mentions:read,channels:history,channels:join,files:write,groups:history,im:history,im:write,incoming-webhook,mpim:history,mpim:write,users:read,users:read.email,chat:write&user_scope='
              )
      }
      icon={slack}
      isPermitted={permissions.includes('integrations')}
    />
  )
}
