import styled from 'styled-components'

export const StyledItemFormsContainer = styled.div`
  background-color: white;
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  border-radius: 12px;
`
export const StyledItemFormsContent = styled.div`
  display: flex;
`
export const StyledItemFormsNumber = styled.div`
  color: rgb(83, 51, 193);
  background-color: rgb(226, 231, 255);
  border-radius: 5px;
  padding: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  margin: 0px 10px;
`
export const StyledItemFormsQuestion = styled.div`
  font-weight: bold;
  line-height: 25px;
  width: 80%;
`
export const StyledItemFormsAnswer = styled.div`
  margin: 10px 45px;
`
export const StyledItemFormsOptions = styled.div`
  width: 20%;
`
