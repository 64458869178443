import React, { useContext } from 'react'
import CardIntegration from '../../components/cardIntegration'
import teams from '../../assets/integrations/teams.svg'
import JamyContext from '../../context/jamyContext'

const TeamsIntegration: React.FC = () => {
  const { permissions} = useContext(JamyContext)
  return (
    <CardIntegration
      category="meetings"
      description="Jamy will join and record your Teams meetings"
      name="Teams"
      isConnected={true}
      icon={teams}
      isPermitted={permissions.includes('teams')}
    />
  )
}

export default TeamsIntegration
