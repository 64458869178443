import React, { useContext } from 'react'
import CardIntegration from '../../components/cardIntegration'
import googleMeet from '../../assets/integrations/google_meet.svg'
import JamyContext from '../../context/jamyContext'

const GoogleMeetIntegration: React.FC = () => {
  const { permissions } = useContext(JamyContext)
  return (
    <CardIntegration
      category="meetings"
      description="Jamy will join and record your Meet meetings"
      name="Google Meet"
      isConnected={true}
      icon={googleMeet}
      isPermitted={permissions.includes('google')}
    />
  )
}

export default GoogleMeetIntegration
