import React, { useEffect, useState } from 'react'
import { StyledH2V2 } from '../../../styles/styledComponents'
import ItemForms, { PropsItemForms } from '../../itemForms'

type PropsForms = {
  name: string
  formsTemplateItem: any[]
}

const Forms: React.FC<PropsForms> = ({ formsTemplateItem, name }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: 'column'
      }}
    >
      <StyledH2V2>{name}</StyledH2V2>
      {formsTemplateItem &&
        formsTemplateItem.length > 0 &&
        formsTemplateItem.map((item, index) => {
          return (
            <ItemForms
              result={item}
              key={index}
              index={index + 1}
            />
          )
        })}
    </div>
  )
}

export default Forms
